import React, { useRef, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

import "../../styles/pages/homepage.scss";
import paginationArrowsRightMobile from "../../../static/images/slider/arrows-right-mobile-new.svg";
import paginationArrowsLeftMobile from "../../../static/images/slider/arrows-left-mobile-new.svg";

const HomepageSliderMobile = ({ content }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const swiperRef = useRef(null);

  const onSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.realIndex);
  };

  const slideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };

  return (
    <div className="slider-container d-block d-xl-none">
      <Swiper
        ref={swiperRef}
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        speed={1600}
        autoplay
        loop
        effect="fade"
        onSlideChange={(swiper) => setActiveSlideIndex(swiper.realIndex)}
      >
        {content.hero_slider.map((slide, index) => (
          <SwiperSlide
            key={slide.slide_title.text}
            className="inner-slider"
            data-test={index}
          >
            {slide.image_mobile.gatsbyImageData !== null && (
              <GatsbyImage
                image={slide.image_mobile.gatsbyImageData}
                alt={slide.image_mobile.alt || "slider image"}
                className="slider-img w-100"
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="slide-title w-100">
        <PrismicRichText field={content.slider_title.richText} />
      </div>

      <div className="mobile-slider-box text-center pt-2 justify-content-center gx-0">
        <div className="d-flex justify-content-center mb-5">
          <Col
            id="col0"
            className={`p-0 mt-3 ${activeSlideIndex === 0 ? "active-0" : ""}`}
            onClick={() => slideTo(0)}
          >
            <div className="p-1">
              <h2 className="pag-title mb-3">
                {content.hero_slider[0].slide_title.text}
              </h2>
              <div className="slider-pagination d-flex justify-content-center">
                <img
                  src={paginationArrowsLeftMobile}
                  className="opacity-0 arrows"
                  alt="pagination left arrows"
                />
                <div className="default" />

                <img
                  className={`arrows arrows-right ${
                    activeSlideIndex === 1 ? "green-pagination" : ""
                  }`}
                  src={paginationArrowsRightMobile}
                  alt="pagination right arrows"
                />
              </div>
            </div>
          </Col>
          <Col
            id="col1"
            className={`p-0 mt-3 ${activeSlideIndex === 1 ? "active-1" : ""}`}
            onClick={() => slideTo(1)}
          >
            <div className="p-1">
              <h2 className="pag-title mb-3">
                {content.hero_slider[1].slide_title.text}
              </h2>
              <div className="slider-pagination d-flex justify-content-center">
                <img
                  className="arrows arrows-left"
                  src={paginationArrowsLeftMobile}
                  alt="pagination left arrows"
                />
                <div className="default" />
                <img
                  className="arrows arrows-right"
                  src={paginationArrowsRightMobile}
                  alt="pagination right arrows"
                />
              </div>
            </div>
          </Col>
          <Col
            id="col2"
            className={`p-0 mt-3 ${activeSlideIndex === 2 ? "active-2" : ""}`}
            onClick={() => slideTo(2)}
          >
            <div className="p-1">
              <h2 className="pag-title mb-3">
                {content.hero_slider[2].slide_title.text}
              </h2>
              <div className="slider-pagination d-flex justify-content-center">
                <img
                  className={`arrows arrows-left ${
                    activeSlideIndex === 1 ? "green-pagination" : ""
                  }`}
                  src={paginationArrowsLeftMobile}
                  alt="pagination left arrows"
                />
                <div className="default" />
                <img
                  src={paginationArrowsRightMobile}
                  className="opacity-0 arrows"
                  alt="pagination right arrows"
                />
              </div>
            </div>
          </Col>
        </div>

        <Row className="mb-4 text-white fade-text justify-content-center">
          <div className="col-11 col-md-9">
            <PrismicRichText
              field={
                content.hero_slider[activeSlideIndex].slide_description.richText
              }
            />
          </div>
        </Row>
      </div>
    </div>
  );
};

export default HomepageSliderMobile;
