import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import ServiceSchema from "../components/meta/ServiceSchema";
import Layout from "../components/common/Layout";
import PageBanner from "../components/elements/PageBanner";
import components from "../components/slices/index";
import { constructActiveDoc } from "../utilities/helpers";
import "../styles/pages/loan_page.scss";

const LoanPage = ({ data }) => {
  if (!data) return null;

  const { page } = data;

  return (
    <div className={data.page.type}>
      <Seo page={page} />
      <ServiceSchema page={page} settings={data.settings} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <PageBanner
          title={page.data.title}
          bgImage={page.data.banner_image}
          bgImageMobile={page.data.image_mobile}
          page={page}
        />
        <SliceZone components={components} slices={page.data.body} />
        {/* {page.url === "/spiti-mou-2/" ? (
          <iframe
            title="fastbot"
            style={{
              width: "400px",
              height: "600px",
              position: "fixed",
              right: "0",
              bottom: "10px",
              zIndex: "9999",
            }}
            src="https://app.fastbots.ai/embed/cm21xpwdb2yn9n8bl8pbq06qa"
          />
        ) : null} */}
      </Layout>
    </div>
  );
};

export const query = graphql`
  query loanPageQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicLoanPage(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicLoanPageFragment
    }
  }
`;

export default LoanPage;
